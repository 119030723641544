<template>
  <div class="app-container">
    <el-form :model="searchForm" size="small" ref="searchForm" inline>
      <!-- 身份验证，如果不是超过只显示自己公司名  如果超管就可以选择公司 -->
      <el-form-item label="公司名称：" prop="ucId">
        <!-- <el-select v-model.number="searchForm.ucId" :disabled="roleId != 1" placeholder="请选择" @change="getDepatmentTrees" clearable filterable style="width:400px">
          <el-option v-for="company in companyList" :label="company.ucName" :value="company.ucId" :key="company.ucId"></el-option>
        </el-select> -->
        <el-select v-model="searchForm.ucId" :disabled="true" style="width:300px">
          <el-option v-for="company in companyList" :label="company.ucName" :value="company.ucId" :key="company.ucId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门名称：">
        <el-input v-model="searchForm.udName" placeholder="部门名"></el-input>
      </el-form-item>
      <el-form-item label="仓库：">
        <el-input v-model="searchForm.warehouseName" placeholder="仓库名"></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="getListDepartment">查询</el-button>
      <el-button size="small" @click="onClear()">清空</el-button>
      <el-button type="success" size="small" v-show="searchForm.udSupervisorId" @click="parentSearch()">上级部门</el-button>
    </el-form>

    <!-- 所选公司的部门树形结构 -->
    <el-row style="height:calc(100vh - 200px);" v-loading="isLoading">
      <el-col :span="3" style="height: calc(100vh - 190px);overflow-y: auto;" v-show="nodews.length > 0">
        <el-tree :props="defaultNode" :data="nodews" @node-click='nodeClick' highlight-current default-expand-all>
        </el-tree>
      </el-col>
      <el-col :span="21">
        <el-table
          :data="dataList"
          border
          height="calc(100vh - 200px)"
          style="width:100%;"
          size="small"
          :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
          <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
          <el-table-column label="部门名称" prop="udName" align="center"></el-table-column>
          <el-table-column label="邮箱" prop="email" align="center"></el-table-column>
          <el-table-column label="状态" prop="udstatus" align="center"></el-table-column>
          <el-table-column label="仓库" prop="warehouseName" align="center"></el-table-column>
          <el-table-column label="排序" prop="udSort" align="center"></el-table-column>
          <el-table-column label="备注" prop="description" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="添加子部门" placement="top">
                <i class="table_icons el-icon-plus" @click="addClick(scope.row)"></i>
              </el-tooltip>
              <el-tooltip effect="dark" content="编辑" placement="top">
                <i class="table_icons el-icon-edit" @click="editClick(scope.row.udId)"></i>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top">
                <i class="table_icons el-icon-delete" @click="deleteDepartment(scope.row)"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="paginationVisible" class="block" style="text-align: center;margin-top:0.5em">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>

    <!-- 新增 编辑 -->
    <edit-dialog
      :editVisable="editVisable"
      :editData="editData"
      :roleId="roleId"
      :companyList="companyList"
      @closeDialog="closeDialog()"
      @addSuccess="addSuccess"/>
  </div>
</template>

<script>
import {
  searchDepartment,
  deleteUd,
  gettree
} from '@/api/departmentalList'
import editDialog from './components/DepartmentEdit'

export default {
  components: {
    'edit-dialog': editDialog
  },
  data() {
    return {
      isLoading: false,
      searchForm: {
        ucId: process.env.VUE_APP_COMPANY_ID, // 公司Id
        udName: '', // 部门名
        pageNum: '',
        pageSize: '',
        warehouseName: '', // 仓库名
        udSupervisorId: '' // 上级部门
      },
      companyList: [{ ucId: process.env.VUE_APP_COMPANY_ID, ucName: process.env.VUE_APP_COMPANY_NAME, ucParentId: 1023, hasMore: false }],
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      nodews: [],
      defaultNode: {
        label: 'Name',
        children: 'children'
      },
      // 初始化数据
      roleId: '',
      ucId: '',
      editVisable: false,
      editData: {},
      paginationVisible: false
    }
  },
  created() { // 生命周期调用
    this.start()
  },
  mounted() {
  },

  methods: {
    start() {
      // 身份Id
      this.roleId = this.$store.getters.roles.userInfo.roleId
      // 用户携带的公司Id
      // this.ucId = this.$store.getters.roles.userInfo.ucId
      // // 获取所有公司
      // getCompanyList()
      //   .then(response => {
      //     if (response.code === '000000' && response.data) {
      //       this.companyList = response.data
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      // this.searchForm.ucId = this.ucId
      this.getDepatmentTrees()
    },
    getDepatmentTrees() {
      this.isLoading = true
      gettree(this.searchForm.ucId)
        .then(response => {
          this.isLoading = false
          if (response.code === '000000' && response.data) {
            this.read_json_only(response.data)
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
    },
    read_json_only(dict_str) {
      for (const i in dict_str) {
        dict_str[i].Name = dict_str[i].udName
        if (typeof dict_str[i].children === 'object') {
          this.read_json_only(dict_str[i].children)
        }
      }
      this.nodews = dict_str
      this.dataList = dict_str
    },
    // 侧边树结构点击
    nodeClick(node) {
      this.dataList = node.children
      this.paginationVisible = false
    },
    getListDepartment() {
      this.paginationVisible = true
      this.searchForm.pageNum = this.pageNum
      this.searchForm.pageSize = this.pageSize
      this.searchDepartmentApi(this.searchForm)
    },
    // 查询部门数据
    searchDepartmentApi(params) {
      this.isLoading = true
      searchDepartment(params).then(response => {
        if (response.code === '000000' && response.data.list) {
          this.isLoading = false
          this.dataList = response.data.list
          this.total = response.data.total
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListDepartment()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListDepartment()
    },
    // 点击查询上级列表
    parentSearch() {
      this.searchForm.udSupervisorId = ''
      this.getListDepartment()
    },
    deleteDepartment(row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true
          deleteUd(row.udId).then(response => {
            this.isLoading = false
            if (response.code === '000000') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getDepatmentTrees()
              const PageNumChangeData = {
                ucId: row.ucId,
                udSupervisorId: row.udSupervisorId,
                pageNum: 1,
                pageSize: 10
              }
              this.searchDepartmentApi(PageNumChangeData)
            } else {
              this.$message.error('删除失败')
            }
          }).catch(() => {
            this.isLoading = false
          })
        })
        .catch(() => {
          this.isLoading = false
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    closeDialog(type) {
      this.editVisable = false
    },
    // 数据有更新 重新获取数据
    addSuccess(params) {
      this.editVisable = false
      // 超管选择其他公司添加数据 则查询所添加公司数据
      if (params.ucId !== this.searchForm.ucId) {
        this.searchForm.ucId = params.ucId
      }
      this.getDepatmentTrees()
      this.searchDepartmentApi(params)
    },
    addClick(row) {
      this.editData.type = 'add'
      this.editData.ucId = row.ucId
      this.editData.udSupervisorId = row.udId
      this.editVisable = true
    },
    editClick(udId) {
      this.editData.type = 'edit'
      this.editData.udId = udId
      this.editVisable = true
    },
    onClear() {
      // 搜索表单初始化
      this.searchForm.udName = ''
      this.searchForm.warehouseName = ''
      this.searchForm.udSupervisorId = ''
    }
  }
}
</script>

<style>

</style>

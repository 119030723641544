<template>
  <el-dialog :title="editData.type === 'add'?'添加部门':'编辑部门'" :visible.sync="editVisable" width="800px" :close-on-click-modal="false" :before-close="closeEditDialog" v-ismove>
    <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="100px" size="small" inline>
      <el-form-item label="公司名称:" style="width:100%">
        <label readOnly="true">{{editUcName}}</label>
      </el-form-item>
      <!-- <el-form-item v-else label="公司名称" prop="ucId" style="width:100%">
        <el-select filterable v-model.number="editForm.ucId" placeholder="请选择" @change="changeCompany()">
          <el-option v-for="company in companyList" :label="company.ucName" :value="company.ucId"
            :key="company.ucId">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="部门名称:" prop="udName">
        <el-input v-model="editForm.udName" clearable></el-input>
      </el-form-item>
      <el-form-item label="部门状态:" prop="udstatus">
        <el-select v-model="editForm.udstatus" placeholder="部门状态" clearable>
          <el-option label="封存" value="封存"></el-option>
          <el-option label="未封存" value="未封存"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱:" prop="email">
        <el-input v-model="editForm.email" clearable></el-input>
      </el-form-item>
      <el-form-item label="仓库:">
        <el-cascader clearable show-all-levels :options="wareHouseOptions" v-model="selectedWareHouse"
          @change="wareHouseChange" :props="wareHouseProps" :placeholder="warehouseName">
        </el-cascader>
      </el-form-item>
      <el-form-item label="排序:" prop="udSort">
        <el-input v-model.number="editForm.udSort" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注:" prop="description">
        <el-input v-model="editForm.description" clearable></el-input>
      </el-form-item>
     </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEditDialog">取消</el-button>
        <el-button type="primary" size="small" @click="commit()">确 定</el-button>
      </div>
    </el-dialog>
</template>

<script>
import {
  getUserSysAndWare,
  getIdData,
  editUdIdData,
  addUd
} from '@/api/departmentalList'
import { required, requiredC, isIntegerNum, validateEmail } from '@/utils/validate'

export default {
  props: {
    editVisable: {
      type: Boolean,
      default() {
        return false
      }
    },
    editData: {
      type: Object,
      default() {
        return {}
      }
    },
    roleId: {
      type: Number,
      default() {
        return 0
      }
    },
    companyList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      editUcName: '',
      editForm: {
        ucId: null, // 公司Id
        udId: null, // 部门Id
        udName: '', // 部门名
        email: '',
        udSupervisorId: '', // 上级部门
        warehouseId: '', // 仓库
        udSort: null, // 排序
        udstatus: '', // 状态
        description: '' // 备注
      },
      formRules: {
        ucId: [requiredC()],
        udName: [required()],
        email: [validateEmail()],
        warehouseId: [requiredC()],
        udSort: [required(), isIntegerNum()],
        udstatus: [requiredC()]
      },
      wareHouseOptions: [],
      selectedWareHouse: [],
      wareHouseProps: {
        value: 'usId',
        label: 'usName',
        children: 'warehouseList'
      },
      // 多于的字段
      warehouseName: ''
    }
  },
  created() {
    // 获取用户所拥有的系统和仓库
    this.getUserSystemWare()
  },
  watch: {
    editVisable: function() {
      if (this.editVisable && this.editData.type === 'edit') {
        this.getDepartmentInfo(this.editData.udId)
      } else if (this.editVisable && this.editData.type === 'add') {
        this.getAddInfo()
      }
    }
  },
  methods: {
    getUserSystemWare() {
      getUserSysAndWare().then(response => {
        if (response.code === '000000' && response.data) {
          // element框架级联要求，层级的key需要替换一样的
          let str = JSON.stringify(response.data).replace(/warehouseId/g, 'usId')
          str = str.replace(/warehouseName/g, 'usName')
          this.wareHouseOptions = JSON.parse(str)
        }
      })
    },
    // 编辑 获取该职位信息，拿到职位名和公司Id
    getDepartmentInfo(udId) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.warehouseList = ''
      getIdData(udId).then(res => {
        loading.close()
        if (res.code === '000000' && res.data) {
          this.editUcName = res.data.ucName
          this.editForm.ucId = res.data.ucId
          this.editForm.udId = res.data.udId
          this.editForm.udName = res.data.udName
          this.editForm.udSort = res.data.udSort
          this.editForm.email = res.data.email
          this.editForm.udSupervisorId = res.data.udSupervisorId
          this.editForm.warehouseId = res.data.warehouseId
          // 因为级联数据默认选中和后台返回的数据不一致的缘故，使用占位符代替显示
          this.warehouseName = res.data.warehouseName
          const array = this.wareHouseOptions
          const ids = []
          for (var i = 0; i < array.length; i++) {
            for (var j = 0; j < array[i].warehouseList.length; j++) {
              if (array[i].warehouseList[j].usId === this.editForm.warehouseId) {
                ids.push(array[i].usId)
              }
            }
          }
          ids.push(this.editForm.warehouseId)
          this.selectedWareHouse = ids
        }
      })
        .catch(err => {
          loading.close()
          console.log(err)
        })
    },
    // 获取公司和上级部门 暂不确定区分超管
    getAddInfo() {
      // if (this.roleId === 1) {
      //   this.editForm.ucId = ''
      // } else {
      this.editForm.ucId = this.editData.ucId
      this.editForm.udSupervisorId = this.editData.udSupervisorId
      // }
    },
    // 公司改变重置上级部门数据
    changeCompany() {
      this.editForm.udSupervisorId = ''
    },
    wareHouseChange(value) {
      this.editForm.warehouseId = value[value.length - 1]
    },
    commit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.editData.type === 'add') {
            this.commitAdd()
          } else {
            this.commitEdit()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 添加
    commitAdd() {
      delete this.editForm.udId
      addUd(this.editForm).then(response => {
        if (response.code === '000000') {
          this.successTip('添加成功')
          const PageNumChangeData = {
            ucId: this.editForm.ucId,
            udSupervisorId: this.editForm.udSupervisorId,
            pageNum: 1,
            pageSize: 10
          }
          this.success(PageNumChangeData)
        } else {
          this.closeEditDialog()
          this.fail()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 编辑
    commitEdit() {
      editUdIdData(this.editForm).then(response => {
        this.selectedWareHouse = []
        if (response.code === '000000') {
          this.successTip('修改成功')
          const PageNumChangeData = {
            ucId: this.editForm.ucId,
            udSupervisorId: this.editForm.udSupervisorId,
            pageNum: 1,
            pageSize: 10
          }
          this.success(PageNumChangeData)
        } else {
          this.closeEditDialog()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    closeEditDialog() {
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
      this.$emit('closeDialog')
    },
    success(val) {
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
      this.$emit('addSuccess', val)
    },
    // 成功提示
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    // 错误提示
    fail(msg) {
      this.$message.error(msg)
    }
  }
}
</script>

<style>

</style>
